import { GenericService } from '@/services/api/generic.service';
import { AxiosRequestConfig } from 'axios';
import apiClient from '@/services/api/client/apiClient';
import { Pathway } from '@/models/pathways.model';


export class DomainPathwayService extends GenericService<Pathway> {
  constructor(domainId: string) {
    super();
    this.endpoint = `v1/worklists/domain/${domainId}/pathways`;
  }

  async updatePathways(changes: { pathways: Partial<Pathway> }, params: AxiosRequestConfig = {}) {
    await apiClient.put(this.endpoint, changes, params);
  }
}
